import { DotsLoader, Switch } from '@eppendorf/vnls-react-components';
import { PhoneNumberVerificationStatus } from '@eppendorf/vnls-user-tenant-utils';
import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useCurrentUserId } from '$shared/custom-hooks/useCurrentUserId';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import {
  CommunicationChannel,
  usePreferencesState,
} from '$features/show-user/preferences/hooks/usePreferencesState';
import { useGetUser } from '$features/users/users.api';

import { PreferencesMessage } from './preferences/preferences-message';

export function UserPreferences(): ReactElement {
  const userId = useSearchParams()[0].get('id') || '';
  const { email: emailFromToken, tenantId } = useTokenAttributes(['email', 'tenantId']);
  const { data: user } = useGetUser(userId);
  const phoneNumberStatus = user?.phoneNumbers?.[0]?.status;
  const { preferences, isLoading, isUpdating, isError, updatePreferences } =
    usePreferencesState();
  const currentUserId = useCurrentUserId(emailFromToken, tenantId);
  const isControlDisabled =
    isUpdating ||
    isError ||
    phoneNumberStatus !== PhoneNumberVerificationStatus.SUCCESSFUL ||
    user?.id !== currentUserId;

  return isLoading ? (
    <DotsLoader className="m-top-7xl m-bottom-7xl" />
  ) : (
    <>
      <div className="font-size-xl font-weight-medium color-gray-900 m-bottom-s">
        <Trans i18nKey="userDetail.preferences.header" />
      </div>
      <div className="color-gray-900 m-bottom-xxl">
        <Trans
          i18nKey="userDetail.preferences.subHeader"
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      </div>
      <div className="flex flex__dir--column gap-l">
        <div className="preference-item flex">
          <div className="w-xs font-weight-bold color-gray-900">
            <Trans i18nKey="userDetail.preferences.email" />
          </div>
          <div>
            {preferences && (
              <Switch
                checked={preferences?.allowEmailCommunication}
                disabled={isControlDisabled}
                onCheckedChange={() => updatePreferences(CommunicationChannel.Email)}
              />
            )}
          </div>
        </div>
        <div className="preference-item flex">
          <div className="w-xs font-weight-bold color-gray-900">
            <Trans i18nKey="userDetail.preferences.sms" />
          </div>
          <div>
            {preferences && (
              <Switch
                checked={preferences?.allowSmsCommunication}
                disabled={isControlDisabled}
                onCheckedChange={() => updatePreferences(CommunicationChannel.SMS)}
              />
            )}
          </div>
        </div>
        <div className="preference-item flex">
          <div className="w-xs m-left-m" />
          <div className="color-gray-900">
            <PreferencesMessage user={user} currentUserId={currentUserId} />
          </div>
        </div>
      </div>
    </>
  );
}
