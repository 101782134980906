import { DotsLoader } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useCurrentUserId } from '$shared/custom-hooks/useCurrentUserId';
import { useTokenAttributes } from '$shared/custom-hooks/useTokenAttributes';

import {
  useControlledSidecar,
  type ControlledSidecarRef,
} from '$components/sidecar/controlled-sidecar-context';
import { EditView } from '$features/edit-user/edit-view';
import {
  PhoneNumberRemovedDialog,
  PhoneNumberRemovedDialogState,
} from '$features/show-user/phone-number-removed-dialog/phone-number-removed-dialog';
import {
  PhoneNumberUpdatedDialog,
  PhoneNumberUpdatedDialogState,
} from '$features/show-user/phone-number-updated-dialog/phone-number-updated-dialog';
import { ReadView } from '$features/show-user/read-view';
import { VerifyPhoneNumberDialogWrapper } from '$features/show-user/verify-phone-number-dialog/verify-phone-number-dialog-wrapper';

interface ShowUserContentProps {
  user: User | undefined;
  isLoading: boolean;
}

export const ShowUserContent = forwardRef<ControlledSidecarRef, ShowUserContentProps>(
  ({ user, isLoading }, ref) => {
    const { formState } = useFormContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

    // Verify phone number dialog
    const [isVerifyPhoneNumberDialogOpen, setIsVerifyPhoneNumberDialogOpen] =
      useState(false);

    // Phone number updated dialog
    const [phoneNumberUpdatedDialogState, setPhoneNumberUpdatedDialogState] =
      useState<PhoneNumberUpdatedDialogState>({
        isOpen: false,
        currentPhoneNumbers: undefined,
        newPhoneNumbers: undefined,
        allowSmsCommunication: undefined,
      });

    // Phone number removed dialog
    const [phoneNumberRemovedDialogState, setPhoneNumberRemovedDialogState] =
      useState<PhoneNumberUpdatedDialogState>({
        isOpen: false,
        currentPhoneNumbers: undefined,
        allowSmsCommunication: undefined,
      });
    const { email, tenantId } = useTokenAttributes(['email', 'tenantId']);
    const currentUserId = useCurrentUserId(email, tenantId);
    const isCurrentUser = currentUserId === user?.id;

    useControlledSidecar(() => {
      const canCloseSidecar = Object.keys(formState.dirtyFields).length === 0;

      if (!canCloseSidecar) {
        setIsCancelDialogOpen(true);
      }

      return canCloseSidecar;
    }, ref);

    // EditView handlers
    const handleEditViewCancel = (): void => {
      setIsEditMode(false);
    };

    const handlePhoneNumbersChange = (props: PhoneNumberUpdatedDialogState): void => {
      setPhoneNumberUpdatedDialogState(props);
    };

    const handlePhoneNumbersRemoved = (props: PhoneNumberRemovedDialogState): void => {
      setPhoneNumberRemovedDialogState(props);
    };

    // ReadView handlers
    const handleShowVerifyPhoneNumberDialog = (): void => {
      setIsVerifyPhoneNumberDialogOpen(true);
    };

    const handleOnEdit = (): void => {
      setIsEditMode(true);
    };

    // VerifyPhoneNumberDialog handlers
    const handleOnVerifyPhoneNumberDialogOpenChange = (open: boolean): void => {
      setIsVerifyPhoneNumberDialogOpen(open);
    };

    // PhoneNumberUpdatedDialog handlers
    const handleClosePhoneNumberUpdatedDialog = (): void => {
      setPhoneNumberUpdatedDialogState({ isOpen: false });
    };

    const handleOnVerifyPhoneNumber = (): void => {
      setIsVerifyPhoneNumberDialogOpen(true);
      setPhoneNumberUpdatedDialogState({ isOpen: false });
    };

    // PhoneNumberRemovedDialog handlers
    const handleClosePhoneNumberRemovedDialog = (): void => {
      setPhoneNumberRemovedDialogState({ isOpen: false });
    };

    if (isLoading) {
      return <DotsLoader className="m-top-xl" />;
    }

    return (
      <>
        {isEditMode ? (
          <EditView
            user={user!}
            isCurrentUser={isCurrentUser}
            onEditViewCancel={handleEditViewCancel}
            onPhoneNumbersChange={handlePhoneNumbersChange}
            onPhoneNumbersRemoved={handlePhoneNumbersRemoved}
            dialogState={[isCancelDialogOpen, setIsCancelDialogOpen]}
          />
        ) : (
          <ReadView
            user={user!}
            onEdit={handleOnEdit}
            onVerifyPhoneNumberClick={handleShowVerifyPhoneNumberDialog}
          />
        )}
        <VerifyPhoneNumberDialogWrapper
          onOpenChange={handleOnVerifyPhoneNumberDialogOpenChange}
          isOpen={isVerifyPhoneNumberDialogOpen}
          selectedUser={user!}
        />
        <PhoneNumberUpdatedDialog
          isOpen={phoneNumberUpdatedDialogState.isOpen}
          onOpenChange={handleClosePhoneNumberUpdatedDialog}
          currentPhoneNumbers={phoneNumberUpdatedDialogState.currentPhoneNumbers}
          newPhoneNumbers={phoneNumberUpdatedDialogState.newPhoneNumbers}
          onVerifyPhoneNumber={handleOnVerifyPhoneNumber}
          allowSmsCommunication={phoneNumberUpdatedDialogState?.allowSmsCommunication}
        />
        <PhoneNumberRemovedDialog
          isOpen={phoneNumberRemovedDialogState.isOpen}
          onOpenChange={handleClosePhoneNumberRemovedDialog}
          currentPhoneNumbers={phoneNumberRemovedDialogState.currentPhoneNumbers}
          allowSmsCommunication={phoneNumberRemovedDialogState?.allowSmsCommunication}
        />
      </>
    );
  },
);
