import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { Overview } from '$components/overview-box/overview-box';
import { TabsNavigationPage } from '$components/tabs/tabs-navigation-page';
import { TabNavigationRoute } from '$components/tabs/tabs.types';
import { AddNotificationRuleDialog } from '$features/notifications/add-or-edit-notification-rule-dialog';
import { useNotificationRulesWithQueryParams } from '$features/notifications/notification-rules.api';

export function NotificationsPage() {
  const { t } = useTranslation();

  // Unify this and incidentTab once the feature flag is deleted
  const notificationRulesTab: TabNavigationRoute[] = [
    {
      label: t('notificationRules.overview'),
      path: 'notifications-rules',
    },
  ];

  const incidentTab: TabNavigationRoute = {
    label: t('incidents.overview'),
    path: 'incidents',
  };

  // When the feature flag won't be needed, move the value for the export tap to the list above and remove this condition
  const notificationsRoutes = isFeatureEnabled('showIncidentsTab')
    ? [incidentTab, ...notificationRulesTab]
    : notificationRulesTab;

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);

  // TODO remove useYggdrasilPath when feature flag will be removed.
  const useYggdrasilPath = isFeatureEnabled('addUpdateDeleteNotificationRulesYggdrasil');

  const {
    data: paginatedData,
    // TODO remove useYggdrasilPath when feature flag will be removed.
  } = useNotificationRulesWithQueryParams({ page: 1, pageSize: 10 }, useYggdrasilPath);
  const notificationsRules = paginatedData ? paginatedData.data : [];

  return (
    <Overview>
      <Overview.Header>
        <h1 className="title">{t('notificationsPage.title')}</h1>

        {notificationsRules?.length > 0 && (
          <Button
            variant="primary"
            className="p-left-l"
            onClick={() => setIsNotificationDialogOpen(true)}
          >
            <Icon
              name="plus"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-s"
            />

            {t('notificationRules.add')}
          </Button>
        )}
      </Overview.Header>
      <Overview.Body>
        <TabsNavigationPage routes={notificationsRoutes} />
        <div className="tab-content m-top-xl">
          <Outlet />
        </div>
      </Overview.Body>
      {isNotificationDialogOpen && (
        <AddNotificationRuleDialog
          isOpen={isNotificationDialogOpen}
          onOpenChange={(open) => setIsNotificationDialogOpen(open)}
        />
      )}
    </Overview>
  );
}
