import { useTranslation } from 'react-i18next';

import { TabsNavigationSidecar } from '$components/tabs';
import type { TabNavigationRoute } from '$components/tabs/tabs.types';

export function UserDetailNavigation() {
  const { t } = useTranslation();

  const userDetailSidecarRoutes: TabNavigationRoute[] = [
    {
      label: t('userDetail.profile.tabTitle'),
      path: 'profile',
      icon: 'user',
    },
    {
      label: t('userDetail.preferences.tabTitle'),
      path: 'preferences',
      icon: 'preferences',
    },
  ];

  return <TabsNavigationSidecar routes={userDetailSidecarRoutes} />;
}
