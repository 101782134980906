import {
  PhoneNumberVerificationStatus,
  type User,
} from '@eppendorf/vnls-user-tenant-utils';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { VerifyPhoneNumberDialogWrapper } from '$features/show-user/verify-phone-number-dialog/verify-phone-number-dialog-wrapper';

type i18messageKey =
  | 'userDetail.preferences.messages.ownProfile.enable'
  | 'userDetail.preferences.messages.ownProfile.verify'
  | 'userDetail.preferences.messages.otherProfile.enable'
  | 'userDetail.preferences.messages.otherProfile.verify'
  | 'userDetail.preferences.messages.common';

export interface PreferencesMessageProps {
  user: User | undefined;
  currentUserId: string | undefined;
}

export function PreferencesMessage({ user, currentUserId }: PreferencesMessageProps) {
  const phoneNumberStatus = user?.phoneNumbers?.[0]?.status;
  const isCurrentUser = user?.id === currentUserId;
  const profileKeyPrefix = isCurrentUser ? 'ownProfile' : 'otherProfile';
  const [isVerifyPhoneNumberDialogOpen, setIsVerifyPhoneNumberDialogOpen] =
    useState(false);

  const getMessage = (key: i18messageKey, linkKey: string) => (
    <>
      <Trans i18nKey="userDetail.preferences.messages.common" />
      <Trans
        i18nKey={`userDetail.preferences.messages.${key}` as i18messageKey}
        components={{
          [linkKey]:
            linkKey === 'profileLink' ? (
              <Link
                to={`/user-management?sidecarRoute=user/profile&id=${currentUserId}`}
                className="color-blue-500"
              />
            ) : (
              <button
                type="button"
                aria-label="Verify phone number"
                className="inline-block color-blue-500 cursor--pointer"
                onClick={() => setIsVerifyPhoneNumberDialogOpen(true)}
              />
            ),
        }}
      />
    </>
  );

  return (
    <>
      {!user?.phoneNumbers?.length &&
        getMessage(`${profileKeyPrefix}.enable` as i18messageKey, 'profileLink')}
      {phoneNumberStatus === PhoneNumberVerificationStatus.PENDING &&
        getMessage(`${profileKeyPrefix}.verify` as i18messageKey, 'verifyLink')}
      {isVerifyPhoneNumberDialogOpen && user && (
        <VerifyPhoneNumberDialogWrapper
          onOpenChange={(open) => setIsVerifyPhoneNumberDialogOpen(open)}
          isOpen={isVerifyPhoneNumberDialogOpen}
          selectedUser={user}
        />
      )}
    </>
  );
}
