import {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';

import { useDeviceTypeConfig } from '$components/app-config/useDeviceTypeConfig';
import { findRealtimeForParam } from '$features/monitoring/shared/find-realtime-for-param';

/**
 * Return the DeviceTypeConfig
 * - merging "config.parameters" with "custom.parameters" for a given device
 * - only return parameters that have a corresponding realtime data
 * @param device The device information
 * @returns The DeviceTypeConfig for the given model name or null if no DeviceTypeConfig is found
 */
export function useDeviceTypeConfigMergedParams(
  device?: RealtimeDevice | null,
): DeviceTypeConfig<DeviceModels> | null {
  const deviceTypeConfig = useDeviceTypeConfig(device);

  if (!device || !deviceTypeConfig) {
    return null;
  }

  return {
    ...deviceTypeConfig,
    parameters: [
      ...deviceTypeConfig.parameters,
      ...(deviceTypeConfig.custom?.[device.model as DeviceModels]?.parameters ?? []),
    ].filter((param) => {
      const foundRealtime = findRealtimeForParam(device.realtime, param);
      return !!foundRealtime;
    }),
  };
}
