import { useSearchParams } from 'react-router-dom';

import { useControlledSidecarContext } from '$components/sidecar/controlled-sidecar-context';
import { FormWrapper } from '$features/show-user/form-wrapper';
import { ShowUserContent } from '$features/show-user/show-user-content';
import { useGetUser } from '$features/users/users.api';

export function UserProfile() {
  const [searchParams] = useSearchParams();
  const sidecarRef = useControlledSidecarContext();
  const { data, isLoading } = useGetUser(searchParams.get('id') || '');

  return (
    <FormWrapper user={data}>
      <ShowUserContent user={data} isLoading={isLoading} ref={sidecarRef} />
    </FormWrapper>
  );
}
