import { Incident } from '@eppendorf/vnls-incidents-utils';
import { PaginatedResponse } from '@eppendorf/vnls-user-tenant-utils';
import { useQuery } from '@tanstack/react-query';

import { fetcher } from '$shared/fetch';
import {
  createPathWithQueryParams,
  INCIDENTS_BASE_PATH,
  objectToURLSearchParams,
} from '$shared/utils/api-paths';

export const incidentsQueryKey = 'incidents';

export const createIncidentsQueryWithQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
  queryParams: any,
) => ({
  queryKey: [incidentsQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<PaginatedResponse<Incident>>(
      createPathWithQueryParams(INCIDENTS_BASE_PATH, queryParams),
    ),
  keepPreviousData: true,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
export const useIncidentsWithQueryParams = (queryParams: any) =>
  useQuery(createIncidentsQueryWithQueryParams(queryParams));
