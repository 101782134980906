import {
  DeviceModels,
  DeviceTypeConfig,
} from '@eppendorf/vnls-application-configuration-service-types';
import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Trans } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useMonitoringExportWithQueryParams } from '$features/monitoring/export/monitoring-export.api';
import { createRequestParameter } from '$features/monitoring/export/monitoring-export.utils';
import { useRealtimeDevice } from '$features/monitoring/monitoring.graphql.api';
import { useDeviceTypeConfigMergedParams } from '$features/monitoring/shared/useDeviceTypeConfigMergedParams';

export function MonitoringExport() {
  const [searchParams] = useSearchParams();

  const deviceManufacturer = searchParams.get('manufacturer')!;
  const deviceSerialNumber = searchParams.get('serialNumber')!;

  const { data: realtimeDataDevice } = useRealtimeDevice(
    deviceManufacturer,
    deviceSerialNumber,
  );

  const deviceTypeConfig: DeviceTypeConfig<DeviceModels> | null =
    useDeviceTypeConfigMergedParams(realtimeDataDevice);

  const { isError, isLoading, mutate } = useMonitoringExportWithQueryParams(
    createRequestParameter(deviceTypeConfig, realtimeDataDevice),
  );

  const startDownload = async () => mutate();

  return (
    <div className="flex flex__dir--column flex__ai--center p-xxl w-full h-full text-align-center">
      <span className="m-bottom-l">
        <Icon name="csv" className="bg-blue-500" size={IconSizeClasses.Large} />
      </span>

      <div className="font-size-xl font-weight-medium m-bottom-m">
        <Trans i18nKey="monitoringDetail.exportDetail.title" />
      </div>
      <div className="m-bottom-m">
        <Trans i18nKey="monitoringDetail.exportDetail.hint" components={{ br: <br /> }} />
      </div>
      <div className="m-bottom-xxl">
        <Trans
          i18nKey="monitoringDetail.exportDetail.note"
          components={{ bold: <span className="font-weight-bold" /> }}
        />
      </div>

      <Button onClick={startDownload} disabled={isLoading} className="m-bottom-xxl">
        <Trans i18nKey="monitoringDetail.exportDetail.buttonText" />
      </Button>

      {isError && (
        <div className="font-size-xl font-weight-medium color-red-600">
          <Trans i18nKey="monitoringDetail.exportDetail.errorMessage" />
        </div>
      )}
    </div>
  );
}
