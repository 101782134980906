import { UserPreferences } from '@eppendorf/vnls-user-tenant-utils';
import { useSearchParams } from 'react-router-dom';

import {
  useGetUserPreferences,
  useUpdateUserPreferences,
} from '$features/users/users.api';

export enum CommunicationChannel {
  SMS = 'sms',
  Email = 'email',
}

export const usePreferencesState = () => {
  const userId = useSearchParams()[0].get('id') || '';
  const {
    data: preferences,
    isLoading: isPreferencesFetching,
    isError: isPreferencesFetchingError,
    refetch,
  } = useGetUserPreferences(userId);
  const {
    mutate,
    isError: isPreferencesUpdatingError,
    isLoading: isUpdating,
  } = useUpdateUserPreferences(userId);
  const isError = isPreferencesFetchingError || isPreferencesUpdatingError;

  const updatePreferences = (type: CommunicationChannel) => {
    const newPreferences = { ...preferences } as UserPreferences;

    // Ensure that at least one communication channel is enabled
    if (type === CommunicationChannel.SMS) {
      newPreferences.allowSmsCommunication = !preferences?.allowSmsCommunication;

      if (!newPreferences.allowSmsCommunication) {
        newPreferences.allowEmailCommunication = true;
      }
    }
    if (type === CommunicationChannel.Email) {
      newPreferences.allowEmailCommunication = !preferences?.allowEmailCommunication;

      if (!newPreferences.allowEmailCommunication) {
        newPreferences.allowSmsCommunication = true;
      }
    }

    mutate(newPreferences);
  };

  return {
    preferences,
    isLoading: isPreferencesFetching,
    isUpdating,
    isError,
    updatePreferences,
    refetch,
  };
};
