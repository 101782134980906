import { QueryClient } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

import {
  isFeatureEnabled,
  isFeatureEnabledInCache,
} from '$shared/feature-toggle/is-feature-enabled';

import {
  appConfigQueryKey,
  createGetFeatureToggleQuery,
} from '$components/app-config/app-config.api';

// When the feature flag showIncidentsTab will be removed this component is not needed.
// This component was used to redirect correctly when the /notifications path is hit
// If the feature flag is on it should go to incidents otherwise it should go to notifications rules.
export async function AsyncRedirectLoader(queryClient: QueryClient) {
  await isFeatureEnabledInCache(
    queryClient,
    'showIncidentsTab',
    createGetFeatureToggleQuery(),
    [appConfigQueryKey, 'feature-toggles'],
  );
}

export function AsyncRedirect() {
  const showIncidentsTab = isFeatureEnabled('showIncidentsTab');

  return <Navigate to={showIncidentsTab ? 'incidents' : 'notifications-rules'} replace />;
}
