import {
  NotificationRule,
  Pagination as PaginiationType,
} from '@eppendorf/vnls-notification-service-types';
import {
  Badge,
  Button,
  ButtonBase,
  Icon,
  IconSizeClasses,
  SortableTable,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useRouteError, useSearchParams } from 'react-router-dom';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Pagination } from '$components/table/pagination/pagination';
import { searchDeviceIcon } from '$features/devices/devices.utils';
import { getEventIconName } from '$features/monitoring/events/events.utils';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';
import { AddNotificationRuleDialog } from '$features/notifications/add-or-edit-notification-rule-dialog';
import { severities } from '$features/notifications/add-or-edit-notification-rule-dialog/screens/condition-screen';
import {
  useAddedNotificationRule,
  useNotificationRulesWithQueryParams,
} from '$features/notifications/notification-rules.api';

export function NotificationRuleList(): ReactElement {
  const navigate = useNavigate();
  const routeError = useRouteError();
  const [{ page, pageSize }, setPagination] = useState<PaginiationType>({
    page: 1,
    pageSize: 10,
  });

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);

  const { t } = useTranslation();

  // TODO remove useYggdrasilPath when feature flag will be removed.
  const useYggdrasilPath = isFeatureEnabled('addUpdateDeleteNotificationRulesYggdrasil');

  const {
    data: paginatedData,
    isError,
    isLoading,
    refetch,
    // TODO remove useYggdrasilPath when feature flag will be removed.
  } = useNotificationRulesWithQueryParams({ page, pageSize }, useYggdrasilPath);

  const { data: addQueryState } = useAddedNotificationRule();

  useDidMountEffect(() => {
    refetch();
  }, [page, pageSize]);

  const notifications = paginatedData ? paginatedData.data : [];

  useEffect(() => {
    if (addQueryState && addQueryState.length > 0) {
      setPagination({ pageSize, page: 1 });
    }
  }, [addQueryState]);

  function handleRowClick(notification: NotificationRule) {
    navigate({
      search: `?sidecarRoute=notifications&id=${notification.id}`,
    });
  }

  const handlePageSizeChange = (newPageSize: string) => {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  };

  const handlePageChange = (newPage: number) => {
    setPagination({ pageSize, page: newPage });
  };

  const badgeColor = () => <Badge color="green">{t('notificationRules.active')}</Badge>;

  const columnHelper = createColumnHelper<NotificationRule>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {
        header: () => t('shared.status'),
        cell: () => badgeColor(),
      }),
      columnHelper.accessor('name', {
        header: () => t('notificationRulesTable.name'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('selectedDeviceType', {
        header: () => t('deviceTable.type'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => {
          const deviceType = info.getValue() ?? 'freezer';
          const { subjects } = info.row.original;
          const filteredSubjects = subjects.filter(
            (subject) => subject.type === 'device',
          );
          return (
            <div className="flex flex__ai--center">
              <div className="flex flex__ai--center gap-s">
                <div className="w-3xs h-3xs">
                  <Icon
                    name={searchDeviceIcon(deviceType)}
                    size={IconSizeClasses.Small}
                  />
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
                {t(`deviceType.${deviceType}` as any)}
              </div>
              <Badge className="m-left-s" color="blue" size="small">
                {filteredSubjects.length.toString()}
              </Badge>
            </div>
          );
        },
      }),
      columnHelper.accessor('tags', {
        header: () => t('notificationRulesTable.condition'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => {
          const tags = info.getValue();
          return (
            <div className="flex flex-wrap flex__jc--flex-start gap-m">
              {tags &&
                severities
                  .map((severity) => severity.name)
                  .filter((tag) => tags.includes(tag))
                  .map((tag) => (
                    <span key={`tag-${info.getValue()}-${info.row.id}-${tag}`}>
                      <Icon
                        name={`event-${getEventIconName(tag)}`}
                        size={IconSizeClasses.XSmall}
                      />
                    </span>
                  ))}
            </div>
          );
        },
      }),
      columnHelper.accessor('recipients', {
        header: () => t('notificationRulesTable.recipients'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => {
          const recipients = info.getValue();

          return (
            <div className="flex flex__ai--center">
              <span className="ellipsize">{recipients[0].name}</span>
              {recipients.length > 1 && (
                <Badge className="m-left-s whitespace-nowrap" color="blue" size="small">
                  {`+ ${(recipients.length - 1).toString()}`}
                </Badge>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor('createdBy', {
        header: () => t('notificationRulesTable.createdBy'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.display({
        id: 'actions',
        header: () => t('shared.actions'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: () => (
          // The problem: When rendering the Dialog multiple times for each row,
          // the Dialog re-renders (=closes itself) when the row is re-rendered
          // (e.g. when /notifications are being refetched)
          // The solution: We render the Dialog only once, and control it via state.
          <ButtonBase
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon name="delete" size={IconSizeClasses.XSmall} className="bg-gray-500" />
          </ButtonBase>
        ),
      }),
    ],
    [],
  );

  const [searchParams] = useSearchParams();

  return (
    <>
      {isNotificationDialogOpen && (
        <AddNotificationRuleDialog
          isOpen={isNotificationDialogOpen}
          onOpenChange={(open) => setIsNotificationDialogOpen(open)}
        />
      )}

      {(routeError || isError) && <LoadingErrorHint />}

      <div className="p-bottom-xxl p-top-m">{t('notificationRules.hint')}</div>

      {!isLoading && !isError && (
        <>
          {notifications && !isLoading && paginatedData?.pagination && (
            <>
              <SortableTable<NotificationRule[], NotificationRule>
                data={notifications}
                columns={columns}
                onRowClick={(notification) => handleRowClick(notification)}
                isSelectedRow={(notification) =>
                  isValueWithKeyInSearchParams('id', notification.id, searchParams)
                }
              />
              {notifications.length > 0 && (
                <Pagination
                  paginationData={paginatedData.pagination}
                  onPageSizeChange={(size) => handlePageSizeChange(size)}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              )}
            </>
          )}

          {!isLoading && !isError && (!notifications || notifications.length === 0) && (
            <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
              <p className="m-bottom-xxl">{t('notificationRulesTable.emptyState')}</p>

              <Button size="small" onClick={() => setIsNotificationDialogOpen(true)}>
                <Icon
                  name="plus"
                  className="bg-white m-right-xs"
                  size={IconSizeClasses.Small}
                />
                {t('notificationRules.add')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
