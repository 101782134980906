import { Incident } from '@eppendorf/vnls-incidents-utils';
import { Pagination as PaginiationType } from '@eppendorf/vnls-notification-service-types/dist/pagination.definitions';
import { Icon, IconSizeClasses, SortableTable } from '@eppendorf/vnls-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useRouteError } from 'react-router-dom';

import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';
import {
  getFormatedLocalizedDateNoTime,
  getLocalizedTimeWithSeconds,
} from '$shared/utils/date.utils';

import { DeviceNameCell } from '$components/device/name-cell';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Pagination } from '$components/table/pagination/pagination';
import { useIncidentsWithQueryParams } from '$features/incidents/incidents.api';
import { IncidentListEmptyMessage } from '$features/incidents-list/incident-list-empty-message';
import { IncidentsStatusBadge } from '$features/incidents-list/incidents-status';
import { getEventIconName } from '$features/monitoring/events/events.utils';
import { AddNotificationRuleDialog } from '$features/notifications/add-or-edit-notification-rule-dialog';
import { useNotificationRulesWithQueryParams } from '$features/notifications/notification-rules.api';

export function IncidentsList(): ReactElement {
  const showIncidentsTab = isFeatureEnabled('showIncidentsTab');
  const routeError = useRouteError();
  const [{ page, pageSize }, setPagination] = useState<PaginiationType>({
    page: 1,
    pageSize: 10,
  });

  // TODO remove useYggdrasilPath when feature flag will be removed.
  const useYggdrasilPath = isFeatureEnabled('addUpdateDeleteNotificationRulesYggdrasil');

  const {
    data: paginatedData,
    // TODO remove useYggdrasilPath when feature flag will be removed.
  } = useNotificationRulesWithQueryParams({ page, pageSize }, useYggdrasilPath);
  const notifications = paginatedData ? paginatedData.data : [];

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);

  const {
    data: paginatedIncidentsData,
    isError,
    isLoading,
  } = useIncidentsWithQueryParams({ page, pageSize });
  const incidents = paginatedIncidentsData ? paginatedIncidentsData.data : [];
  const { t } = useTranslation();

  const columnHelper = createColumnHelper<Incident>();

  function handlePageSizeChange(newPageSize: string) {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  }

  function handlePageChange(newPage: number) {
    setPagination({ pageSize, page: newPage });
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('incidentStatus', {
        header: () => t('shared.status'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => <IncidentsStatusBadge incidentStatus={info.getValue()} />,
      }),
      columnHelper.accessor('createdAt', {
        header: () => t('incidentsTable.createdAt'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => (
          <div>
            <span className="font-weight-bold">
              {getFormatedLocalizedDateNoTime(info.renderValue() || '')}
            </span>
            <p>{getLocalizedTimeWithSeconds(info.renderValue() || '')}</p>
          </div>
        ),
      }),
      columnHelper.accessor('notifications', {
        header: () => t('incidentsTable.type'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: ({
          row: {
            original: { notifications: notificationsInfo },
          },
        }) => {
          const notification = notificationsInfo[0];
          return (
            <div className="flex flex__ai--center gap-m">
              <span key={`tag-${notification.level}-${notification.id}`}>
                <Icon
                  name={`event-${getEventIconName(notification.level)}`}
                  size={IconSizeClasses.XSmall}
                />
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor('event.message', {
        header: () => t('incidentsTable.event'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('device.deviceName', {
        header: () => t('incidentsTable.device'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: ({
          row: {
            original: { device },
          },
        }) => <DeviceNameCell device={device} />,
      }),
      columnHelper.accessor('triggeredBy.name', {
        header: () => t('incidentsTable.triggeredBy'),
        cell: (info) => info.renderValue(),
      }),
    ],
    [],
  );

  return showIncidentsTab ? (
    <>
      {isNotificationDialogOpen && (
        <AddNotificationRuleDialog
          isOpen={isNotificationDialogOpen}
          onOpenChange={(open) => setIsNotificationDialogOpen(open)}
        />
      )}

      <div className="p-bottom-m p-top-m">{t('incidents.hint')}</div>
      {(routeError || isError) && <LoadingErrorHint />}

      {!isLoading && !isError && (
        <>
          {incidents && (
            <>
              <SortableTable<Incident[], Incident>
                data={incidents || []}
                columns={columns}
              />
              {incidents.length > 0 && (
                <Pagination
                  paginationData={paginatedIncidentsData?.pagination}
                  onPageSizeChange={(size) => handlePageSizeChange(size)}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              )}
            </>
          )}

          {!isLoading && !isError && (!incidents || incidents?.length === 0) && (
            <IncidentListEmptyMessage
              notificationRulesEmpty={!notifications || notifications?.length === 0}
              openNotificationDialog={setIsNotificationDialogOpen}
            />
          )}
        </>
      )}
    </>
  ) : (
    <Navigate to="/notifications/notifications-rules" replace />
  );
}
