import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';

/**
 * Formats the first phone number in the provided array of phone numbers.
 *
 * @param phoneNumbers - An array of PhoneNumber objects or undefined.
 * @returns A formatted phone number string in the format "countryCode number".
 * If no phone number is provided, returns a hyphen ("-").
 */
export const getDisplayPhoneNumber = (phoneNumbers: PhoneNumber[] | undefined) => {
  const dash = '-';

  if (!phoneNumbers || !phoneNumbers.length) return dash;

  const phoneNumber = phoneNumbers?.[0];
  const formattedPhoneNumber = `${phoneNumber?.countryCode?.trim() || ''} ${phoneNumber?.number?.trim() || ''}`;

  return formattedPhoneNumber || dash;
};
