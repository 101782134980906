import { QueryClient } from '@tanstack/react-query';

import { SidecarRoute } from '$components/sidecar-router/sidecar-router.definitions';
import { AssociatedMonitors } from '$features/devices/associated-monitors/associated-monitors';
import { AssociatedRouter } from '$features/devices/associated-router/associated-router';
import { DeviceInformation } from '$features/devices/device-information/device-information';
import { DeviceNotes } from '$features/devices/device-information/device-notes';
import { MonitoringEvents } from '$features/monitoring/events/monitoring-events';
import { MonitoringExport } from '$features/monitoring/export/monitoring-export';
import { MonitoringHistoryCharts } from '$features/monitoring/history-charts/monitoring-history-charts';
import { MonitoringParameters } from '$features/monitoring/parameters/monitoring-parameters';
import { UserPreferences } from '$features/show-user/user-preferences';
import { UserProfile } from '$features/show-user/user-profile';
import {
  DeviceDetailPage,
  deviceDetailLoader,
} from '$pages/devices/detail/device-detail-page';
import {
  MonitoringDetailPage,
  monitoringDetailLoader,
} from '$pages/monitoring/detail/monitoring-detail-page';
import {
  NotificationRuleDetailPage,
  notificationRuleDetailLoader,
} from '$pages/notifications/detail/notification-detail-page';
import {
  UserDetailPage,
  userDetailLoader,
} from '$pages/user-management/detail/user-detail-page';

enum SideCarRoutes {
  Device = 'device',
  Monitoring = 'monitoring',
  Notification = 'notifications',
  User = 'user',
}

export function createSidecarRoutes(
  queryClient: QueryClient,
): Record<SideCarRoutes, SidecarRoute> {
  return {
    [SideCarRoutes.Device]: {
      element: <DeviceDetailPage />,
      loader: async ({ params }) => {
        if (
          !params.get('manufacturer') ||
          params.get('manufacturer') === null ||
          !params.get('serialNumber') ||
          params.get('serialNumber') === null
        ) {
          return Promise.reject(new Error('Invalid params'));
        }

        return deviceDetailLoader(queryClient, {
          manufacturer: params.get('manufacturer')!,
          serialNumber: params.get('serialNumber')!,
        });
      },
      children: {
        details: {
          element: <DeviceInformation />,
        },
        notes: {
          element: <DeviceNotes />,
        },
        associatedRouter: {
          element: <AssociatedRouter />,
        },
        associatedMonitors: {
          element: <AssociatedMonitors />,
        },
      },
    },
    [SideCarRoutes.Monitoring]: {
      element: <MonitoringDetailPage />,
      loader: async ({ params }) => {
        if (!params.get('manufacturer') || !params.get('serialNumber')) {
          return Promise.reject(new Error('Invalid params'));
        }

        return monitoringDetailLoader(queryClient, {
          manufacturer: params.get('manufacturer')!,
          serialNumber: params.get('serialNumber')!,
        });
      },
      children: {
        parameters: {
          element: <MonitoringParameters />,
        },
        'history-charts': {
          element: <MonitoringHistoryCharts />,
        },
        events: {
          element: <MonitoringEvents />,
        },
        export: {
          element: <MonitoringExport />,
        },
      },
    },
    [SideCarRoutes.Notification]: {
      element: <NotificationRuleDetailPage />,
      loader: async ({ params }) => {
        if (!params.get('id') || params.get('id') === null) {
          return Promise.reject(new Error('Invalid params'));
        }

        return notificationRuleDetailLoader(queryClient, params.get('id')!);
      },
    },
    [SideCarRoutes.User]: {
      element: <UserDetailPage />,
      loader: async ({ params }) => {
        if (!params.get('id') || params.get('id') === null) {
          return Promise.reject(new Error('Invalid params'));
        }

        return userDetailLoader(queryClient, params.get('id')!);
      },
      children: {
        profile: {
          element: <UserProfile />,
        },
        preferences: {
          element: <UserPreferences />,
        },
      },
    },
  };
}
