import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';

/**
 * Checks if there is any change in phone numbers or country codes between two arrays of phone numbers.
 *
 * @param currentPhoneNumbers - The first array of phone numbers to compare.
 * @param newPhoneNumbers - The second array of phone numbers to compare.
 * @returns `true` if there is a change in phone numbers or country codes; otherwise, `false`.
 */
export const hasPhoneNumberOrCountryCodeChanged = (
  currentPhoneNumbers: PhoneNumber[] | undefined,
  newPhoneNumbers: PhoneNumber[] | undefined,
): boolean => {
  const current = currentPhoneNumbers || [];
  const updated = newPhoneNumbers || [];

  const addedFirstNumber = current.length === 0 && updated.length > 0;
  const removedNumber = current.length > 0 && updated.length === 0;

  // First number added, or removed we don't consider it as a change
  // and we don't want to show the dialog
  if (addedFirstNumber || removedNumber) {
    return false;
  }

  // If sizes differ, something has changed
  if (current.length !== updated.length) {
    return true;
  }

  // Serialize phone numbers for comparison
  const serializePhoneNumber = (phone: PhoneNumber) =>
    `${phone.countryCode}${phone.number}`;

  const currentSet = new Set(current.map(serializePhoneNumber));
  const updatedSet = new Set(updated.map(serializePhoneNumber));

  // Compare the sets for differences
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-restricted-syntax -- Set iterator
  for (const phone of currentSet) {
    if (!updatedSet.has(phone)) {
      return true;
    }
  }

  return false;
};
