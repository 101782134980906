import { Icons } from '@eppendorf/vnls-css/dist';
import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import cn from 'classnames';

import styles from '$components/tabs/tabs-navigation.module.scss';
import type { TabNavigationRoute } from '$components/tabs/tabs.types';

export interface TabProps {
  routes: TabNavigationRoute[];
  isActivePath: (path: string) => boolean;
  handleTabClick: (path: string) => void;
}

export function Tabs({ routes, isActivePath, handleTabClick }: TabProps) {
  return (
    <ul className="flex border-solid border-color-gray-400 border-bottom-s">
      {routes.map((route) => (
        <li
          className={cn(
            'flex flex__ai--center cursor--pointer m-right-xxl border-solid border-bottom-m ',
            {
              'border-color-blue-500': isActivePath(route.path),
              'border-color-white': !isActivePath(route.path),
            },
          )}
          key={route.path}
        >
          <Button
            variant="tertiary"
            className={cn('interactive', styles.navigationButton, {
              'color-gray-900': !isActivePath(route.path),
            })}
            onClick={() => handleTabClick(route.path)}
          >
            {route.icon && (
              <Icon
                size={IconSizeClasses.Small}
                name={route.icon as Icons}
                className={cn('masked', 'm-right-s', {
                  'bg-blue-500': isActivePath(route.path),
                })}
              />
            )}
            <span
              className={cn({
                'p-bottom-m': !route.icon,
                'p-top-m': !route.icon,
              })}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- we know it exists */}
              {route.label}
            </span>
          </Button>
        </li>
      ))}
    </ul>
  );
}
