import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';

/**
 * Checks if the phone number has been removed.
 *
 * This function compares the current phone numbers with the new phone numbers
 * and determines if all phone numbers have been removed.
 *
 * @param currentPhoneNumbers - The array of current phone numbers or undefined.
 * @param newPhoneNumbers - The array of new phone numbers or undefined.
 * @returns `true` if all phone numbers have been removed, otherwise `false`.
 */
export const hasPhoneNumberRemoved = (
  currentPhoneNumbers: PhoneNumber[] | undefined,
  newPhoneNumbers: PhoneNumber[] | undefined,
): boolean => {
  const current = currentPhoneNumbers || [];
  const updated = newPhoneNumbers || [];

  const removedNumber = current.length > 0 && updated.length === 0;

  if (removedNumber) {
    return true;
  }

  return false;
};
