import { Sidecar } from '@eppendorf/vnls-react-components';
import type { HasChildren } from '@eppendorf/vnls-react-components';
import { useRef } from 'react';

import {
  type ControlledSidecarRef,
  ControlledSidecarProvider,
} from '$components/sidecar/controlled-sidecar-context';

interface ControlledSidecarProps extends HasChildren {
  isOpen: boolean;
  onOpenChange: () => void;
}

export function ControlledSidecar({
  isOpen,
  onOpenChange,
  children,
}: ControlledSidecarProps) {
  const onCloseRef = useRef<ControlledSidecarRef>(null);

  const handleClosing = () => {
    const shouldClose = onCloseRef.current?.onSideCarClose();

    if (shouldClose || typeof shouldClose !== 'boolean') {
      onOpenChange();
    }
  };

  return (
    <ControlledSidecarProvider onCloseRef={onCloseRef}>
      <Sidecar isOpen={isOpen} onOpenChange={() => handleClosing()}>
        {children}
      </Sidecar>
    </ControlledSidecarProvider>
  );
}
