import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { AxiosError } from 'axios';
import React, { FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { VerifyPhoneScreens } from '$features/show-user/verify-phone-number-dialog/types';
import { VerificationCodeInput } from '$features/show-user/verify-phone-number-dialog/verify-code-component/verify-code-component';
import {
  useReportPhoneVerification,
  useStartPhoneVerification,
} from '$features/users/users.api';

export interface VerifyNumberScreenProps {
  selectedUser: User;
  goToNextScreen: (screen: VerifyPhoneScreens) => void;
}

const CODE_LENGTH = 4;

export function VerifyNumberScreen({
  selectedUser,
  goToNextScreen,
}: VerifyNumberScreenProps) {
  const { t } = useTranslation();

  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disablingResendCode, setDisablingResendCode] = useState(false);
  const [showError, setShowError] = useState(false);
  const { mutateAsync: mutateAsyncStartPhoneVerification } = useStartPhoneVerification();
  const { mutateAsync: mutateAsyncReportPhoneVerification } =
    useReportPhoneVerification();

  const handleCodeChange = (newCode: string) => {
    setShowError(false);
    setVerificationCode(newCode);
  };
  const verifyCode = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const userPhoneNumber = selectedUser.phoneNumbers?.[0];
      const { firstName } = selectedUser;
      const { lastName } = selectedUser;

      if (userPhoneNumber && firstName && lastName) {
        await mutateAsyncReportPhoneVerification({
          phoneNumber: {
            number: userPhoneNumber.number,
            countryCode: userPhoneNumber.countryCode,
          },
          verificationCode,
        });
      }
    } catch (error: unknown) {
      const { response } = error as AxiosError;
      if (response && response.status === 400) {
        // Display error message that the code doesn't match
        setShowError(true);
      }

      if (response && response.status === 500) {
        // internal server error move to the Error screen
        goToNextScreen(VerifyPhoneScreens.ErrorVerificationScreen);
      }

      setIsLoading(false);
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }

    // Call the BE accepting the terms and conditions.
    goToNextScreen(VerifyPhoneScreens.SuccessVerificationScreen);
    setIsLoading(false);
  };

  const resendCode = async () => {
    setDisablingResendCode(true);
    setShowError(false);
    setVerificationCode('');
    try {
      const userPhoneNumber = selectedUser.phoneNumbers?.[0];

      if (userPhoneNumber) {
        await mutateAsyncStartPhoneVerification({
          phoneNumber: {
            number: userPhoneNumber.number,
            countryCode: userPhoneNumber.countryCode,
          },
        });
      }
    } catch {
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }
  };

  const selectedUserPhoneNumber = selectedUser.phoneNumbers?.[0];

  return (
    <form onSubmit={verifyCode}>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="phone"
          className="bg-blue-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('verifyPhoneNumber.verifyPhoneNumberDialog.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.verifyPhoneNumberDialog.description"
            values={{
              phoneNumber: `${selectedUserPhoneNumber?.countryCode} ${selectedUserPhoneNumber?.number}`,
            }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </Dialog.Description>
        <div className="flex flex__dir--row gap-s text-align-left m-bottom-l">
          <VerificationCodeInput
            hasError={showError}
            handleCodeChange={handleCodeChange}
            codeLength={CODE_LENGTH}
            errorMessage={t(
              'verifyPhoneNumber.verifyPhoneNumberDialog.verificationCodeDoesntMatch',
            )}
            cleanInputValues={disablingResendCode}
          />
        </div>

        <div className="flex flex__dir--row m-bottom-l">
          {disablingResendCode ? (
            <>
              <Icon
                name="success"
                className="bg-green-500"
                size={IconSizeClasses.XSmall}
              />
              <Trans
                className="font-size-l"
                i18nKey="verifyPhoneNumber.verifyPhoneNumberDialog.codeResent"
                components={{
                  p: <p className="m-left-xs m-top-xxs color-green-500" />,
                }}
              />
            </>
          ) : (
            <Trans
              className="font-size-l font-weight-regular"
              i18nKey="verifyPhoneNumber.verifyPhoneNumberDialog.resendCode"
              components={{
                button: (
                  <Button
                    onClick={() => resendCode()}
                    className="p-0 m-left-xs"
                    variant="tertiary"
                  />
                ),
              }}
            />
          )}
        </div>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="secondary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
        <Button
          type="submit"
          disabled={verificationCode.length !== CODE_LENGTH || isLoading}
        >
          {t('verifyPhoneNumber.verifyPhoneNumberDialog.verifyButton')}
        </Button>
      </Dialog.Actions>
    </form>
  );
}
