import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import type { User } from '@eppendorf/vnls-user-tenant-utils';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export interface AcceptTermsAndConditionsScreenProps {
  selectedUser: User;
}
export function SuccessVerifyPhoneNumber({
  selectedUser,
}: AcceptTermsAndConditionsScreenProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const { countryCode, number } = selectedUser.phoneNumbers?.[0] ?? {};
  const isTargetRoute = location.search.includes('preferences');

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="success"
          className="bg-green-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('verifyPhoneNumber.successVerifiedPhoneNumber.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.successVerifiedPhoneNumber.description"
            values={{
              phoneNumber: `${countryCode} ${number}`,
            }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </Dialog.Description>
        <div className="flex flex__dir--row gap-s text-align-left m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.successVerifiedPhoneNumber.receiveSMSCopy"
          />
        </div>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="secondary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
        {!isTargetRoute && (
          <Button className="p-x-xxl" type="button" variant="primary">
            <Link
              to={`/user-management?sidecarRoute=user/preferences&id=${selectedUser.id}`}
            >
              <Trans i18nKey="verifyPhoneNumber.successVerifiedPhoneNumber.preferenceButton" />
            </Link>
          </Button>
        )}
      </Dialog.Actions>
    </>
  );
}
