import { Incident } from '@eppendorf/vnls-incidents-utils';
import {
  NotificationRule,
  PaginationResponse,
} from '@eppendorf/vnls-notification-service-types';
import { PaginatedResponse } from '@eppendorf/vnls-user-tenant-utils';
import { QueryClient } from '@tanstack/react-query';

import { isFeatureEnabledInCache } from '$shared/feature-toggle/is-feature-enabled';
import { prepopulateCache } from '$shared/utils/react-query.utils';

import {
  appConfigQueryKey,
  createGetFeatureToggleQuery,
} from '$components/app-config/app-config.api';
import { createIncidentsQueryWithQueryParams } from '$features/incidents/incidents.api';
import { createNotificationRulesQueryWithQueryParams } from '$features/notifications/notification-rules.api';
import { NotificationsPage } from '$pages/notifications/notifications-page';

export async function incidentsLoader(queryClient: QueryClient): Promise<null> {
  return prepopulateCache<PaginatedResponse<Incident>>(
    queryClient,
    createIncidentsQueryWithQueryParams({ page: 1, pageSize: 10 }),
  );
}

export async function notificationRulesLoader(queryClient: QueryClient): Promise<null> {
  // TODO: remove this code when feature flag will be removed.
  const isNotificationServiceV2Enabled = await isFeatureEnabledInCache(
    queryClient,
    'addUpdateDeleteNotificationRulesYggdrasil',
    createGetFeatureToggleQuery(),
    [appConfigQueryKey, 'feature-toggles'],
  );

  return prepopulateCache<{ data: NotificationRule[]; pagination: PaginationResponse }>(
    queryClient,
    createNotificationRulesQueryWithQueryParams(
      { page: 1, pageSize: 10 },
      isNotificationServiceV2Enabled,
    ),
  );
}

export async function notificationsLoader(queryClient: QueryClient): Promise<null> {
  return Promise.all([
    incidentsLoader(queryClient),
    notificationRulesLoader(queryClient),
    // // Remove this lines when the feature flags gone
    isFeatureEnabledInCache(
      queryClient,
      'showIncidentsTab',
      createGetFeatureToggleQuery(),
      [appConfigQueryKey, 'feature-toggles'],
    ),
  ])
    .then((values) => Promise.resolve(null))
    .catch(() => Promise.resolve(null));
}

export function Notifications() {
  return <NotificationsPage />;
}
