export const en = {
  translation: {
    shared: {
      cancel: 'Cancel',
      edit: 'Edit',
      save: 'Save',
      saveChanges: 'Save changes',
      unsavedChangesWarning: 'You have unsaved changes',
      unsavedChangesQuestion: 'Do you want to discard your changes?',
      unsavedEditDescription: `You haven't saved your changes to the notification rule yet. If you close now, all your edits will be lost.`,
      close: 'Close',
      closeWithoutSaving: 'Close without saving',
      discard: 'Discard',
      clear: 'Clear',
      noDataToShow: 'No data to show',
      continue: 'Continue',
      continueEdit: 'Continue editing',
      logout: 'Logout',
      status: 'Status',
      actions: 'Actions',
      delete: 'Delete',
      intlDateTime: '{{val, dateTime}}',
      email: 'Email',
      back: 'Back',
      tryAgain: 'Try again',
      loading: 'Loading...',
      later: 'Later',
      updateNow: 'Update Now',
      verifyNumber: 'Verify number',
    },
    validationMessages: {
      required: 'This field can not be empty.',
      maxLength120: 'Field must not exceed 120 characters.',
      searchMaxLength120: 'You have reached the search term limit of 120 characters.',
      maxLength2500: 'Field must not exceed 2500 characters.',
      phoneNumberRequired: 'Phone number is required',
      invalidPhoneNumber:
        'White spaces, leading zeros, and special characters are not allowed.',
      countryCodeRequired: 'Country code is required',
      phoneNumberMinLength: 'Phone number must be at least 5 digits long',
    },
    errors: {
      oops: 'Oops!',
      unexpected: 'Sorry, an unexpected error has occurred.',
      unauthorized: 'You are unauthorized to execute this actions.',
      informationCouldNotBeLoaded: 'Information could not be loaded',
      contactSupport: 'If the problem persists',
      contactSupport2: 'contact the support.',
      errorOccurred: 'An error occurred',
    },
    serialNumbers: {
      enter: 'Enter the serial number',
      submit: 'Submit serial number',
      validationMessage: 'This does not seem to be a correct serial number.',
      serialNumberDuplicateErrorMessage:
        'A device with this serial number has been added previously. Please check if the serial number for the device you try to add is correct.',
    },
    devices: {
      overview: 'Device overview',
      serialNumber: 'Serial number',
    },
    addDevice: {
      add: 'Add device',
      connectable: 'Connectable',
      notConnectable: 'Not connectable',
      isAdded: 'Device added',
      selectDeviceTypeInfo:
        'To add a new device, please select one of the following options to guide you through the process.',
      connectableDevice: 'Connectable device',
      waiting: 'Waiting for connection',
      waitingInfo: 'Ensure your device is switched on and connected to the network.',
      waitingInfoSenseMonitor:
        'Ensure your Sense Gateway is registered in VisioNize. It needs to be near your Sense Monitor. Please check that the device is switched on and connected to the network.',
      addError: 'Registration could not be completed',
      addErrorInfo:
        'The registration process for the following device could not be completed.',
      successInfo:
        '{{type}} with the serial number <strong>{{serialNumber}}</strong> was successfully added. You can now start monitoring your device or setup a notification rule for it.',
      retryRegistration: {
        hint: 'Device registration process could not be completed.',
        pleaseWait: 'Registration process running. Please wait.',
      },
    },
    deviceType: {
      freezer: 'Freezer',
      incubator: 'Incubator',
      monitor: 'Monitor',
      gateway: 'Gateway',
      'sense-monitor': 'Sensor',
      'sense-gateway': 'Gateway',
      unknown: 'Unknown',
    },
    deviceTable: {
      pairNow: 'Pair now',
      type: 'Device type',
      name: 'Device name',
      manufacturer: 'Manufacturer',
      model: 'Device model',
      emptyState: 'Add your first device in VisioNize.',
    },
    deviceConnectionStatus: {
      pending: 'Pairing pending',
      waitingForMetadata: 'Pending',
      paired: 'Connected',
      readyToPair: 'Ready to pair',
    },
    deviceDetail: {
      fallbackHeadlineNameEmpty: 'Device',
      tabs: {
        details: 'Details',
        notes: 'Notes',
        associatedRouter: 'Associated Router',
        associatedMonitors: 'Associated Monitors',
        associatedMonitorsDialog: {
          blockedTitle: 'Monitor blocked',
          unblockedTitle: 'Monitor unblocked',
          blockedDescription:
            'The Visionize Sense environment monitor  <bold>{{associatedMonitorName}}</bold> is now blocked from connecting to the Visionize Sense gateway router <bold>{{gatewayName}}.</bold>',
          unblockedDescription:
            'The Visionize Sense environment monitor  <bold>{{associatedMonitorName}}</bold> is now unblocked from connecting to the Visionize Sense gateway router <bold>{{gatewayName}}.</bold>',
          disclaimer: 'The change can take some minutes to reflect in connection status.',
        },
      },
      deviceInformation: {
        generalInformation: 'General Information',
        location: 'Location',
        address: 'Address',
        site: 'Site',
        building: 'Building',
        room: 'Room',
        position: 'Position',
        country: 'Country',
        countrySelectPlaceHolder: 'Select a country',
        noCountryOptions: 'No countries to select',
        countryISOCodeNotFound: '{{code}} (Country ISO code not found)',
        street: 'Street',
        postcode: 'Postcode',
        city: 'City',
        state: 'State',
        company: 'Company',
        deviceType: 'Device type',
        deviceName: 'Device name',
        serialNumber: 'Serial number',
        customInventoryId: 'Custom inventory ID',
        manufacturer: 'Manufacturer',
        deviceModel: 'Device model',
        notes: 'Notes',
        softwareVersion: 'Software version',
        pairingInformation: 'Pairing Information',
        updateAvailable: 'Update available',
        updateDialog: {
          title: 'Software update',
          description:
            'Updates for the following <bold>VisioNize Sense gateway router</bold> are available:',
          disclaimer:
            '<bold>Note</bold>: Updating the gateway router might effect the <bold>VisioNize sense sensors</bold> device as well. Please check the release notes for further details',
        },
      },
      routerTable: {
        connection: 'Connection',
        signal: 'Signal',
        router: 'Gateway router',
        emptyMessage:
          'No router associated. Please make sure a Visionize Sense gateway router is switched on and in the vicinity of your sensor.',
      },
      monitorsTable: {
        connection: 'Connection',
        monitors: 'Monitors',
        block: 'Block',
        emptyMessage:
          'No monitor associated. Please make sure a Visionize Sense monitor is switched on and in the vicinity of your router.',
      },
      routerStatus: {
        stable: 'Stable',
        unstable: 'Unstable',
        noConnection: 'Not connected',
        blocked: 'Blocked',
      },
    },
    pairing: {
      success: 'You can now use all features of VisioNize with this device.',
    },
    deviceModels: {
      F740: 'Cyrocube® F740 Freezer',
    },
    monitoring: {
      overview: 'Monitoring',
      activityStatus: 'Activity status',
      device: 'Device',
      alertStatus: 'Alert status',
      doorOrLid: 'Door/Lid',
      emptyState: 'No devices connected',
      emptyStateHint:
        'Either add a device or check the device connectivity to start monitoring',
      gotoDeviceOverview: 'Go to device overview',
    },
    monitoringDetail: {
      parameters: 'Parameters',
      'history-charts': 'History charts',
      events: 'Events',
      export: 'Export',
      exportDetail: {
        title: 'Export your weekly data',
        hint: 'CSV reports will be generated for all process value measures <br/>from the history charts and for all device events.',
        note: '<bold>Please note:</bold> data export is only available for the last seven days.',
        buttonText: 'Download as ZIP',
        errorMessage: 'Error downloading report. Please try again.',
      },
      historyChartsDetail: {
        deviceNotConfigured: 'Device not configured to show history charts.',
        showAlertThresholdsToggleLabel: 'Setpoint and thresholds',
        timezoneHint: 'The data is shown in your local timezone',
        noDataAvailable: 'No data available',
        noChartData: 'No chart data within the chosen time range',
        highLowAlertIndicator: 'High/Low alert',
        setPointIndicator: 'Setpoint',
        processValueIndicator: 'Process value',
        highAlertLabel: 'High alert',
        lowAlertLabel: 'Low alert',
        setPointLabel: 'Setpoint',
        rangePeriod: {
          custom: 'Custom',
          '30days': '30 days',
          '14days': '14 days',
          '7days': '7 days',
          '24hours': '24 hours',
          live: 'Live',
        },
      },
      eventsDetail: {
        type: 'Type',
        time: 'Time',
        event: 'Event',
        eventSearchInputPlaceholder: 'Search for event',
        timezoneHint: 'Events are shown in your local timezone',
        noEventsFound: 'No events found',
        changeSearchFilters: 'Please try changing your search filters',
        resetSearch: 'Reset search',
        newEvents: 'New events',
      },
      overwrite: {
        waterLevel: {
          Ok: 'Normal',
          Low: 'Empty',
        },
        co2Tank: {
          On: 'Filled',
          Error: 'Empty',
          default: 'Check bottle',
        },
      },
      noLiveData: 'No live data',
    },
    alertThresholds: {
      parameterAlert: 'Parameter alerts',
      alertHint:
        'Parameter alerts, setpoint and limits can be set directly on the device. Please look up the device manual for more details.',
      alertHigh: 'Alert high',
      warnHigh: 'Warn high',
      setPoint: 'Set point',
      alertLow: 'Alert low',
      warnLow: 'Warn low',
      unknown: 'Unknown',
      limitsError:
        'Limits must be descending from high to low. <br/>Adjust the parameter values.',
      valueHint: 'Current value',
      valueHintOldTimestamp: 'Value',
      alertThresholdHint: 'Current value within alert range',
      alertThresholdHintOldTimestamp: 'Value within alert range',
      warnThresholdHint: 'Current value within warning range',
      warnThresholdHintOldTimestamp: 'Value within warning range',
      defaultThresholdHint: 'Current value good',
      defaultThresholdHintOldTimestamp: 'Value good',
      loading: 'Saving settings',
      loadingHint: 'Please wait till threshold data gets updated',
    },
    activityStatus: {
      automaticallyRunning: 'Running',
      done: 'Done',
      error: 'Error',
      idle: 'Idle',
      manuallyRunning: 'Running',
      paused: 'Paused',
      offline: 'Unavailable',
      online: 'Running',
      hold: 'Hold',
      running: 'Running',
      unreachable: 'Unreachable',
      preparing: 'Updating',
    },
    notificationsPage: {
      title: 'Notifications',
    },
    incidents: {
      overview: 'Incident history',
      hint: 'View rule-triggered incidents and detailed flow of recipients, acknowledgment or resolution.',
      incidentsStatus: {
        acknowledged: 'Acknowledged',
        escalated: 'Escalated',
        resolved: 'Resolved',
        expired: 'Expired',
        actionRequired: 'Action required',
        noActionRequired: 'No action required',
        unavailable: 'Unavailable',
      },
    },
    incidentsTable: {
      noNotificationReceived: {
        title: 'No incidents yet',
        hint: 'Notifications will appear here once your rules are triggered by an event.',
        viewNotificationsRules: 'View notification rules',
      },
      noNotificationsCreated: {
        hint: 'Create a notification rule to get status updates from your devices',
      },
      createdAt: 'Created',
      event: 'Event',
      triggeredBy: 'Triggered by',
      device: 'Device',
      type: 'Type',
    },
    notificationRules: {
      overview: 'Notification rules',
      hint: 'Manage rules for when notifications are triggered to stay informed of key device events.',
      errorHeadline: 'Notification Rule could not be saved',
      add: 'Add notification rule',
      addSuccessHeadline: 'Notification rule added',
      addSuccessMessage:
        'Notification rule <bold>{{name}}</bold> was successfully added.',
      new: 'New notification rule',
      edit: 'Edit notification rule',
      editSuccessHeadline: 'Notification rule updated',
      editSuccessMessage:
        'Notification rule <bold>{{name}}</bold> was successfully updated.',
      editErrorMessage:
        '<p>Please review your setting and try again.</p><p>Ensure you are connected to the network.</p>',
      editSuccessHint:
        '<bold>Please note:</bold> You may still receive messages based on the previous settings while we transition to the new configuration.',

      addRuleSteps: {
        selectDeviceType: {
          label: 'Select device type',
          fieldDescription:
            'First choose the <bold>device type</bold>, then in the next step you can select either <bold>individual</bold> or <bold>all devices within that type.</bold>',
        },
        chooseDevices: {
          labelPrefix: 'Select',
        },
      },
      inputName: 'Give your notification rule a name',
      defaultNamePrefix: 'Notification rule',
      selectRecipients: 'Select the recipients of this notification rule',
      notificationsChannel: 'Send notifications via',
      active: 'Active',
      inactive: 'Inactive',
      error: {
        nameAlreadyExists: 'Name already exists. Please choose a different one.',
        noDevicesFound: 'No devices found',
      },
    },
    notificationRulesTable: {
      name: 'Name',
      tags: 'Event',
      recipients: 'Recipients',
      condition: 'Condition',
      createdBy: 'Created by',
      emptyStateNoPairedDevices:
        'To create a notification rule you first need to add and then pair a device.',
      emptyState:
        'Create a new notification rule to get status updates from your devices.',
      recipientNotice:
        'The notification will be sent to the following recipients via email:',
      tooltip: 'Notification rules define when you get notified by your devices.',
    },
    notificationDetail: {
      notificationRule: 'Notification rule',
      devices: 'Devices',
      recipients: 'Recipients',
      condition: 'Condition',
      createdBy: 'Created',
      tryAgain: 'Please try again later',
      noDevicesFound: 'Error loading devices.',
      errorLoadingDevices: 'Error loading some devices.',
    },
    notificationTags: {
      alert: 'Alert: ',
      warning: 'Warning: ',
      error: 'Error: ',
      info: 'Info: ',
      errorMessage: 'Technical device problem e.g. power failure',
      alertMessage:
        'Critical situation e.g. door/lid left opened too long, parameter exceeded the alert limits',
      warningMessage:
        'Potentially negative condition e.g. device unavailable or parameter exceeded the warning limits',
      infoMessage:
        'Positive informational event e.g. device reconnected or temperature normalized',
    },
    tagsCondition: {
      notify: "You'll be notified on ",
      events: 'all events',
      within: 'within the ',
      severity: 'selected severity type.',
      select: 'Select condition',
    },
    userManagement: {
      overview: 'User overview',
      firstName: 'First name',
      lastName: 'Last name',
      role: 'Role',
      createdAt: 'Created at',
      invitedAt: 'Invited',
      administrator: 'Administrator',
      roleUpdate5MinNotice: 'Role changes may take up to 5 minutes to be effective.',
      contactAdmin: "You can't change your own role, contact an admin to update it.",
      emailNote: "Email is used for verification and can't be edited. ",
      profile: 'Profile',
      status: 'Status',
      resendInvite: 'Resend invite',
      lastUpdated: 'Last updated',
      phoneNumber: 'Mobile number',
      phoneNumberOptional: 'Mobile number (optional)',
      phoneNumberNotVerified: 'Not verified',
      phoneNumberVerified: 'Verified',
      countryCodeSelectPlaceHolder: 'Select a country code',
      limitedSupportedCountriesNotice:
        'Please notice that sms notifications <br/> are only available in certain regions.',
      verifyPhoneNumberNotice:
        'After saving, verify the number to enable SMS notifications.',
      roles: {
        labAdmin: 'Lab Manager',
        labMember: 'Lab Member',
      },
    },
    userStatus: {
      pending: 'Invite sent',
      enabled: 'Active',
      disabled: 'Inactive',
      error: 'Error',
    },
    userInvite: {
      inviteUser: 'Invite user',
      inviteSuccess: 'Invite sent',
      inviteResentSuccessfully: 'Invite successfully resent',
      errorMessageUniqueEMail: 'Email already exists.',
      errorMessageEmailMaxLength: 'The email is too long.',
      errorMessageEmailWrongFormat: 'The format of the email address is wrong.',
      descriptionInviteUser: 'Please enter the details of the new user:',
      descriptionInviteConfirmed:
        'Invite was successfully sent to <bold>{{user}}</bold>. Their status will change to <bold>Active</bold> upon profile creation in VisioNize.',
      descriptionEmailMessageCannotBeChanged:
        'The email address can not be changed later.',
      sendInvite: 'Send invite',
    },
    userDelete: {
      deleteSuccess: 'User deleted',
      deleteError: 'Error on user deletion',
      descriptionUserNotDeleted:
        'User <bold>{{user}}</bold> could not be deleted and can still access VisioNize.',
      descriptionUserDeleted:
        'User <bold>{{user}}</bold> has been successfully deleted and cannot access VisioNize anymore. All of the data created by the user will be kept.',
      deleteUserQuestion: 'Delete user?',
      deleteUserAction: 'Delete user',
      deleteUserLongQuestion:
        'Are you sure you want to delete <bold>{{user}}</bold>? This action cannot be undone.',
    },
    userDetail: {
      title: 'User details',
      myProfile: 'My profile',
      preferences: {
        tabTitle: 'Preferences',
        header: 'Communication preferences',
        subHeader:
          'Notification methods for notification rules <bold>(at least one required).</bold>',
        email: 'Email',
        sms: 'SMS',
        messages: {
          common: 'To enable SMS notifications, ',
          ownProfile: {
            enable: 'please <profileLink>add a phone number</profileLink>.',
            verify: 'please <verifyLink>verify your phone number</verifyLink>.',
          },
          otherProfile: {
            enable: 'the user must add a phone number.',
            verify: 'the user must first verify their number.',
          },
        },
      },
      profile: {
        tabTitle: 'Profile',
      },
    },
    verifyPhoneNumber: {
      title: 'Verify phone',
      termsAndConditionsDialog: {
        title: 'Phone verification',
        verifyPhoneExplanation:
          "Verify your number to receive SMS notifications. We'll send a 4-digit<br/> code to <bold>{{phoneNumber}}</bold> for confirmation.",
        termsAndConditionsCopy:
          'By agreeing, you consent to receive text messages from VisioNize <br/> and accept our <termsLink>terms & conditions</termsLink> and <policyLink>privacy policy</policyLink>.',
        sendVerificationButton: 'Send verification code',
      },
      successVerifiedPhoneNumber: {
        title: 'Phone number verified',
        description: 'Verification is successful for <bold>{{phoneNumber}}</bold>.',
        receiveSMSCopy:
          'To receive SMS notifications, enable this option in preferences.',
        preferenceButton: 'Open preferences',
      },
      errorVerifyingPhoneNumber: {
        title: 'Unable to verify phone number',
        description:
          'Please close this dialog and select <bold>Verify number</bold> to retry. <br/> Ensure you are connected to the network',
        errorCopy: 'If the problem persists ',
        contactSupport: 'contact the support.',
      },
      unableToSendVerificationCode: {
        title: 'Unable to send code',
        description:
          'Please close this dialog and select <bold>Verify number</bold> to retry. <br/> Ensure you are connected to the network',
        errorCopy: 'If the problem persists ',
        contactSupport: 'contact the support.',
      },
      verifyPhoneNumberDialog: {
        title: 'Phone verification',
        description: 'We’ve sent a 4-digit code to <bold>{{phoneNumber}}</bold> via SMS.',
        resendCode: "Didn't get the code? <button>Resend</button>",
        resendButton: 'Resend',
        resendCodeTest: "Didn't get the code?",
        verifyButton: 'Verify',
        codeResent: '<p>Code resent. Please check your phone.</p>',
        verificationCodeDoesntMatch: 'Invalid code. Please check and try again.',
      },
    },
    phoneNumberUpdatedDialog: {
      title: 'Phone number updated',
      description:
        'You’ve changed the phone number from <bold>{{fromPhoneNumber}}</bold> to <bold>{{toPhoneNumber}}</bold>.',
      smsNotificationsDisabled:
        'SMS notifications are disabled now. To activate them again, please verify the new number first.',
      enableSMSCommunication:
        'To enable SMS as a communication preference, please verify the new number first.',
    },
    phoneNumberRemovedDialog: {
      title: 'Phone number removed',
      description:
        'You’ve removed the number <bold>{{phoneNumber}}</bold> from your profile.',
      smsNotificationsDisabled:
        'SMS notifications are now disabled. To enable them again, please add and verify a new number.',
      enableSMSCommunication:
        'To enable SMS as a communication preference, please add and verify a new number.',
    },
    osl: {
      title: 'Open source libraries',
      name: 'Name',
      version: 'Version',
      license: 'License',
      url: 'URL',
      repository: 'Repository',
      publisher: 'Publisher',
      visitWebsite: 'Visit website',
      visitRepository: 'Visit repository',
    },
    header: {
      add: 'Add',
    },
    footer: {
      cookieInfo: 'Cookie info',
      cookieSettings: 'Cookie settings',
      termsAndConditions: 'Terms & conditions',
      usepolicy: 'Acceptable use policy',
      privacyPolicy: 'Privacy policy',
      imprint: 'Imprint',
      about: 'About VisioNize',
      oslibraries: 'Open Source Libraries',
    },
    sidebar: {
      deviceManagement: 'Device management',
      monitoring: 'Monitoring',
      notifications: 'Notifications',
      userManagement: 'User management',
    },
    pagination: {
      pageSize: 'Rows per page',
      itemRange: '{{startItem}} - {{endItem}} of {{totalItems}} items',
    },
    landing: {
      title: 'Welcome to the new VisioNize',
      symbol: '®',
      labSuiteExperienceText: 'Lab Suite experience',
      labSuiteExperienceQuestion: 'Lab Suite Experience?',
      signInHint: 'If you already have an account continue to the sign in page',
      signUpHint: 'No account yet? Get started for free!',
      signUpButton: 'Sign up',
      signInButton: 'Sign in',
      classicVisionizeHint: 'Are you looking for the classic VisioNize',
      classicVisionizeLink: 'Click here',
      createNewAccountHint: 'Create new account',
      setUpAccountHint: 'Please continue to set up your account.',
      setUpButton: 'Set up account',
      policyHint: 'Before using our product, please agree to the',
      policyLink: 'Acceptable Use Policy',
    },
  },
};
