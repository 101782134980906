import { ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tabs } from '$components/tabs/tabs';
import type { TabsNavigationProps } from '$components/tabs/tabs.types';

export function TabsNavigationSidecar({
  routes,
  onTabChange,
  shouldChangeTabOnActive,
}: TabsNavigationProps): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabPath, setActiveTabPath] = useState('');

  function goTo(path: string): void {
    const newRoute = searchParams.get('sidecarRoute')?.replace(/\/[^/]+$/, `/${path}`);
    searchParams.set(
      'sidecarRoute',
      decodeURIComponent(newRoute || searchParams.get('sidecarRoute') || ''),
    );
    setSearchParams(searchParams);
  }

  useEffect(() => {
    if (shouldChangeTabOnActive && activeTabPath) {
      goTo(activeTabPath);
    }
  }, [shouldChangeTabOnActive]);

  const isActivePath = (path: string): boolean =>
    searchParams.get('sidecarRoute')?.endsWith(path) ?? false;

  const handleTabClick = (path: string) => {
    if (onTabChange) {
      onTabChange();
      setActiveTabPath(path);
    } else {
      goTo(path);
      setActiveTabPath('');
    }
  };
  return (
    <Tabs handleTabClick={handleTabClick} isActivePath={isActivePath} routes={routes} />
  );
}
