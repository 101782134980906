import { IncidentStatus, IncidentStatusEnum } from '@eppendorf/vnls-incidents-utils';
import { Badge } from '@eppendorf/vnls-react-components';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './incidents-status.scss';

interface IncidentsStatusBadgeProps {
  incidentStatus: IncidentStatus;
}

export function IncidentsStatusBadge({
  incidentStatus,
}: IncidentsStatusBadgeProps): ReactElement {
  const { t } = useTranslation();
  function getColorBadgeByStatus(status: IncidentStatus | null) {
    switch (status) {
      case IncidentStatusEnum.NoActionRequired:
        return (
          <Badge color="green">{t('incidents.incidentsStatus.noActionRequired')}</Badge>
        );

      case IncidentStatusEnum.Acknowledged:
        return <Badge color="green">{t('incidents.incidentsStatus.acknowledged')}</Badge>;

      case IncidentStatusEnum.Escalated:
        return <Badge color="red">{t('incidents.incidentsStatus.escalated')}</Badge>;

      case IncidentStatusEnum.Resolved:
        return <Badge color="green">{t('incidents.incidentsStatus.resolved')}</Badge>;

      case IncidentStatusEnum.ActionRequired:
        return (
          <Badge className="badge-no-background-color" bordered color="orange">
            {t('incidents.incidentsStatus.actionRequired')}
          </Badge>
        );

      case IncidentStatusEnum.Expired:
        return (
          <Badge className="badge-no-background-color" bordered color="red">
            {t('incidents.incidentsStatus.expired')}
          </Badge>
        );

      default:
        return <Badge>{t('incidents.incidentsStatus.unavailable')}</Badge>;
    }
  }

  return (
    <div className="flex flex__ai--center w-10xl">
      {getColorBadgeByStatus(incidentStatus)}
    </div>
  );
}
