import debounce from 'lodash/debounce';
import { DependencyList, useCallback } from 'react';

const DEFAULT_DEBOUNCE_DELAY = 500;

export const useDebouncedCallback = <Args, T>(
  callback: (...args: Args[]) => T,
  deps?: DependencyList,
  delay = DEFAULT_DEBOUNCE_DELAY,
) => useCallback(debounce(callback, delay), [...(deps || [])]);
