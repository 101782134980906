import {
  PaginatedResponse,
  User,
  CreateUserBody,
  UsersQueryParams,
  UpdateUserBody,
  type UserPreferences,
} from '@eppendorf/vnls-user-tenant-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createPathWithQueryParams,
  createUserPath,
  objectToURLSearchParams,
  REPORT_VERIFICATION,
  START_VERIFICATION,
  USERS_BASE_PATH,
} from '$shared/utils/api-paths';

import {
  ReportVerificationBody,
  StartVerificationBody,
} from '$features/show-user/verify-phone-number-dialog/types';

export const userQueryKey = 'users';
export const preferencesQueryKey = 'preferences';

export const createUsersQueryWithQueryParams = (queryParams: UsersQueryParams) => ({
  queryKey: [userQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<PaginatedResponse<User>>(
      createPathWithQueryParams(USERS_BASE_PATH, queryParams),
    ),
  keepPreviousData: true,
});
export const useUsersWithQueryParams = (queryParams: UsersQueryParams) =>
  useQuery(createUsersQueryWithQueryParams(queryParams));

export const createGetUserQuery = (id: string) => ({
  queryKey: [userQueryKey, id],
  queryFn: async () => fetcher.get<User>(createUserPath(id)),
  enabled: !!id,
});
export const useGetUser = (id: string) => useQuery(createGetUserQuery(id));

export const useAddUser = () => {
  const addUser = (user: CreateUserBody) =>
    fetcher.post<CreateUserBody, User>(USERS_BASE_PATH, user);

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<User>, AxiosError, CreateUserBody>(addUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([userQueryKey]);
    },
  });
};

export const useUpdateUser = () => {
  const updateUser = (user: UpdateUserBody & Pick<User, 'id'>) => {
    const { id, ...userWithoutId } = user;
    return fetcher.patch<UpdateUserBody, User>(createUserPath(id), userWithoutId);
  };

  const queryClient = useQueryClient();
  return useMutation<User, AxiosError, UpdateUserBody & Pick<User, 'id'>>(updateUser, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([userQueryKey]);

      queryClient.setQueryData([userQueryKey, data.id], data);
    },
  });
};

export const useDeleteUser = () => {
  const deleteUser = (id: string) => fetcher.delete(`${USERS_BASE_PATH}/${id}`);
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>(deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries([userQueryKey]);
    },
  });
};

export const useResendInvite = () => {
  const updateInviteSendAt = (id: string) =>
    fetcher.post<unknown, User>(
      `${USERS_BASE_PATH}/${id}/actions/resend-invite`,
      undefined,
    );

  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<User>, AxiosError, string>(updateInviteSendAt, {
    onSuccess: (/* data */) => {
      queryClient.invalidateQueries([userQueryKey]);

      // queryClient.setQueryData([userQueryKey, data.data.id], data);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console -- for debugging
      console.log(error);
    },
  });
};

export const useStartPhoneVerification = () => {
  const startPhoneVerification = (startVerificationBody: StartVerificationBody) =>
    fetcher.post<unknown, StartVerificationBody>(
      `${START_VERIFICATION}`,
      startVerificationBody,
    );

  return useMutation<
    AxiosResponse<StartVerificationBody>,
    AxiosError,
    StartVerificationBody
  >(startPhoneVerification);
};

export const useReportPhoneVerification = () => {
  const reportPhoneVerification = (reportVerificationBody: ReportVerificationBody) =>
    fetcher.post<unknown, ReportVerificationBody>(
      `${REPORT_VERIFICATION}`,
      reportVerificationBody,
    );

  const queryClient = useQueryClient();

  return useMutation<
    AxiosResponse<ReportVerificationBody>,
    AxiosError,
    ReportVerificationBody
  >(reportPhoneVerification, {
    onSuccess: () => {
      queryClient.invalidateQueries([userQueryKey]);
    },
  });
};

export const useGetUserPreferences = (userId: string) =>
  useQuery({
    queryKey: [userId, preferencesQueryKey],
    queryFn: () =>
      fetcher.get<UserPreferences>(`${USERS_BASE_PATH}/${userId}/preferences`),
  });

export const useUpdateUserPreferences = (userId: string) => {
  const queryClient = useQueryClient();

  return useMutation<UserPreferences, AxiosError, UserPreferences>(
    (preferences: UserPreferences) =>
      fetcher.patch<UserPreferences, UserPreferences>(
        `${USERS_BASE_PATH}/${userId}/preferences`,
        preferences,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([userId, preferencesQueryKey]);
      },
    },
  );
};
