import {
  Badge,
  Button,
  Icon,
  IconSizeClasses,
  SortableTable,
  useDidMountEffect,
} from '@eppendorf/vnls-react-components';
import {
  Pagination as PaginationType,
  UserStatus,
  UsersSortParams,
  User,
} from '@eppendorf/vnls-user-tenant-utils';
import { createColumnHelper } from '@tanstack/react-table';
import i18next from 'i18next';
import { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AccessControlGuard } from '$shared/access-control/access-control-guard';
import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';
import { isFeatureEnabled } from '$shared/feature-toggle/is-feature-enabled';

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Overview } from '$components/overview-box/overview-box';
import { Pagination } from '$components/table/pagination/pagination';
import { DeleteUserDialogWrapper } from '$features/delete-user';
import { InviteUserDialogWrapper } from '$features/invite-user';
import { isValueWithKeyInSearchParams } from '$features/navigation/isValueWithKeyInSearchParams';
import { stringifyUserRoles } from '$features/users/roles.utils';
import { useUsersWithQueryParams } from '$features/users/users.api';

const initialPageIndex = 1;
const initialPageSize = 10;

export function getStatusDisplay(status: number | undefined, border = false) {
  switch (status) {
    case UserStatus['Invite Sent']:
      return (
        <Badge bordered={border} className="m-top-xs" color="orange">
          {i18next.t('userStatus.pending')}
        </Badge>
      );
    case UserStatus.Active:
      return (
        <Badge bordered={border} className="m-top-xs" color="green">
          {i18next.t('userStatus.enabled')}
        </Badge>
      );
    case UserStatus.Inactive:
      return <Badge>{i18next.t('userStatus.disabled')}</Badge>;
    case UserStatus.Error:
      return (
        <Badge bordered={border} className="m-top-xs" color="red">
          {i18next.t('userStatus.error')}
        </Badge>
      );
    default:
      return '';
  }
}

export function UsersOverview(): ReactElement {
  const { t } = useTranslation();
  const [isInviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [isDeleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const navigate = useNavigate();
  const [{ page, pageSize }, setPagination] = useState<PaginationType>({
    page: initialPageIndex,
    pageSize: initialPageSize,
  });

  const [sortBy, setSortBy] = useState<UsersSortParams['sort']>('+status');
  const isPermissionsFeatureEnabled = isFeatureEnabled(
    'applyPermissionsToUsersOverviewPage',
  );
  const hasPermissionToDeleteUser = useAccessControlGuard('DELETE_USER');
  const hasPermissionToViewOtherProfileDetails = useAccessControlGuard(
    'VIEW_OTHERS_PROFILE_DETAILS',
  );

  function handlePageSizeChange(newPageSize: string) {
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  }

  function handlePageChange(newPage: number) {
    setPagination({ pageSize, page: newPage });
  }

  function handleDeleteUser(event: SyntheticEvent, user: User) {
    event.stopPropagation();
    setSelectedUser(user);
    setDeleteUserDialogOpen(true);
  }

  const handleUserRowClick = hasPermissionToViewOtherProfileDetails
    ? (user: User) => navigate({ search: `?sidecarRoute=user/profile&id=${user.id}` })
    : undefined;

  function handleSortChange(sortKey: string) {
    if (sortBy === `+${sortKey}`) {
      setSortBy(`-${sortKey}` as UsersSortParams['sort']);
    } else {
      setSortBy(`+${sortKey}` as UsersSortParams['sort']);
    }
  }

  const { data, isLoading, isError, refetch } = useUsersWithQueryParams({
    page,
    pageSize,
    sort: sortBy,
  });

  useDidMountEffect(() => {
    refetch();
  }, [page, pageSize, sortBy]);

  const columnHelper = createColumnHelper<User>();

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('status', {
        id: 'status',
        header: () => t('shared.status'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => getStatusDisplay(info.getValue() as number),
        meta: {
          isSortable: true,
        },
      }),
      columnHelper.accessor('firstName', {
        id: 'firstName',
        header: () => t('userManagement.firstName'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('lastName', {
        id: 'lastName',
        header: () => t('userManagement.lastName'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => t('shared.email'),
        cell: (info) => info.renderValue(),
        meta: {
          isSortable: true,
        },
      }),
      columnHelper.accessor('roles', {
        id: 'roles',
        header: () => t('userManagement.role'),
        cell: (info) => stringifyUserRoles(info.getValue() || []),
      }),
    ];

    if (!isPermissionsFeatureEnabled || hasPermissionToDeleteUser) {
      return [
        ...cols,
        columnHelper.display({
          id: 'actions',
          header: () => t('shared.actions'),
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          cell: (selected) => (
            <Button
              title={t('userDelete.deleteUserAction')}
              onClick={(event) => handleDeleteUser(event, selected.row.original)}
              variant="icon"
            >
              <Icon name="delete" size={IconSizeClasses.XSmall} className="bg-gray-800" />
            </Button>
          ),
        }),
      ];
    }

    return cols;
  }, [isPermissionsFeatureEnabled, hasPermissionToDeleteUser]);

  const [searchParams] = useSearchParams();

  return (
    <Overview>
      <Overview.Header>
        <h1 className="title">{t('userManagement.overview')}</h1>
        <InviteUserDialogWrapper
          isOpen={isInviteUserDialogOpen}
          onOpenChange={(open) => setInviteUserDialogOpen(open)}
        />
        {selectedUser && (
          <DeleteUserDialogWrapper
            isOpen={isDeleteUserDialogOpen}
            selectedUser={selectedUser}
            onOpenChange={(open) => setDeleteUserDialogOpen(open)}
          />
        )}
        {isPermissionsFeatureEnabled ? (
          <AccessControlGuard requiredPermissions="INVITE_USER">
            <Button
              variant="primary"
              className="p-left-l"
              onClick={() => setInviteUserDialogOpen(true)}
            >
              <Icon
                name="plus"
                size={IconSizeClasses.XSmall}
                className="bg-white m-right-s"
              />
              {t('userInvite.inviteUser')}
            </Button>
          </AccessControlGuard>
        ) : (
          <Button
            variant="primary"
            className="p-left-l"
            onClick={() => setInviteUserDialogOpen(true)}
          >
            <Icon
              name="plus"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-s"
            />
            {t('userInvite.inviteUser')}
          </Button>
        )}
      </Overview.Header>
      <Overview.Body>
        {(isLoading || isError) && <LoadingErrorHint />}

        {!isLoading && !isError && data && (
          <>
            <SortableTable<User[], User>
              data={data.data}
              columns={columns}
              onRowClick={handleUserRowClick}
              onSortChange={(sortKey) => handleSortChange(sortKey)}
              currentSortKey={sortBy}
              isSelectedRow={(user) =>
                isValueWithKeyInSearchParams('id', user.id, searchParams)
              }
            />
            <Pagination
              paginationData={data.pagination}
              onPageSizeChange={(size) => handlePageSizeChange(size)}
              onPageChange={(newPage) => handlePageChange(newPage)}
            />
          </>
        )}
      </Overview.Body>
    </Overview>
  );
}
