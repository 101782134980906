import { DeviceParameterConfig } from '@eppendorf/vnls-application-configuration-service-types';
import { Device, RealtimeData } from '@eppendorf/vnls-telemetry-and-events-types';

export function findRealtimeForParam(
  realtimeList: Device['realtime'],
  param: DeviceParameterConfig,
): RealtimeData | undefined {
  const foundRealtime = realtimeList.find(
    (realtime) =>
      realtime &&
      (realtime.subject === param.subject || realtime.name === param.name) &&
      realtime.dataType === 'telemetry' &&
      realtime.subcomponent.indexOf('#') <= 0,
  );

  return foundRealtime || undefined;
}
