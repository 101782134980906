import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '$components/tabs/tabs';
import type { TabsNavigationPageProps } from '$components/tabs/tabs.types';

export function TabsNavigationPage({
  routes,
  shouldChangeTabOnActive,
}: TabsNavigationPageProps): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = location.pathname.split('/').pop() || routes[0]?.path;

  const isActivePath = (path: string): boolean => currentTab === path;

  const handleTabClick = (path: string) => {
    if (!shouldChangeTabOnActive || !isActivePath(path)) {
      navigate(path);
    }
  };

  return (
    <Tabs handleTabClick={handleTabClick} isActivePath={isActivePath} routes={routes} />
  );
}
